<template>
  <div v-if="open"  class="flex flex-row items-start  justify-between bg-black bg-opacity-60 z-20 h-full w-full ">
    <div
      class="
        flex flex-col
        rounded-2xl
        min-h-screen
        w-60
        z-20
        shadow-xl
        items-start
        justify-start
        
        bg-white
        transition duration-700 ease-out ...
      "
    >
      <img
        class="-mx-10 h-20 w-52 mb-8"
        :src="require('@/assets/logo-transparent.png')"
        alt=""
      />
      <div class="space-y-12">
        <div class="space-y-1.5 mx-2">
          <div  class="flex flex-row space-x-4">
            <vue-feather  @click="this.$router.replace('/')"  stroke="grey" type="layout" size="20"> </vue-feather>
            <a   @click="this.$router.replace('/')"  class="text-body text-gray-500 font-normal">Feed</a>
          </div>

          <div class="flex flex-row space-x-4">
            <vue-feather stroke="grey" type="home" size="20"> </vue-feather>
            <a class="text-body text-gray-500 font-normal">Market Place</a>
          </div>
        </div>

        <div class="space-y-1.5 mx-2">
          <div   class="flex flex-row space-x-4">
            <vue-feather @click="this.$router.replace('/profile')"  stroke="grey" type="user" size="20"> </vue-feather>
            <a @click="this.$router.replace('/profile')" class="text-body text-gray-500 font-normal">Profile</a>
          </div>

          <div class="flex flex-row space-x-4">
            <vue-feather stroke="grey" type="settings" size="20"> </vue-feather>
            <a class="text-body text-gray-500 font-normal">Settings</a>
          </div>
          <div class="flex flex-row space-x-4">
            <vue-feather stroke="grey" type="help-circle" size="20">
            </vue-feather>
            <a class="text-body text-gray-500 font-normal">Support</a>
          </div>
        </div>
        <div class="space-y-1.5 mx-2">
          <div class="flex flex-row space-x-4">
            <vue-feather @click="this.$router.replace('/About')" stroke="grey" type="alert-circle" size="20">
            </vue-feather>
            <a @click="this.$router.replace('/About')" class="text-body text-gray-500 font-normal">About</a>
          </div>
        </div>
      </div>
    </div>
    <div class="z-20 w-4 h-4 mx-7 text-orange">
       <vue-feather @click="open=!open" stroke="orange" type="x" size="24">
            </vue-feather>
    </div>
  </div>
</template>

<script>
export default {
    props: {
    open:{
      type: Boolean,
      default: false,
    }
  },

};
</script>

